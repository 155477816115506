import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileSchedulerService {
  private _isMobile: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get $isMobile() {
    return this._isMobile.asObservable();
  }

  setIsMobile(value) {
    this._isMobile.next(value);
  }

  constructor() {}
}
